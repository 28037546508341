'use client';

import {
  Children,
  DetailedHTMLProps,
  HTMLAttributes,
  cloneElement,
  isValidElement,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { InView } from 'react-intersection-observer';

import styles from './Carousel.module.scss';

type CarouselProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: React.ReactNode;
  focusable?: boolean;
  masonry?: boolean;
  perRow?: number;
};

export default function Carousel({
  children,
  className = '',
  focusable = false,
  masonry = false,
  perRow = 3,
  ...props
}: CarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  const [isCarousel, setIsCarousel] = useState(true);

  useLayoutEffect(() => {
    const onResize = () => {
      setIsCarousel(carouselRef.current !== null && carouselRef.current.clientWidth < 60 * 16);
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <>
      <div
        className={classNames(styles.carousel, { [styles.masonry]: masonry })}
        ref={carouselRef}
        {...props}
      >
        {Children.toArray(children)
          .filter(isValidElement)
          .map((child, index) => (
            <InView
              as="div"
              className={classNames(styles.slide, styles[`perRow-${perRow}`])}
              data-carousel-slide
              key={`slide-${index}`}
              onChange={(inView) => inView && setCurrentIndex(index)}
              onFocus={(e) => {
                if (carouselRef.current) {
                  carouselRef.current.scrollLeft =
                    e.target.closest<HTMLElement>('[data-carousel-slide]')?.offsetLeft ?? 0;
                }
              }}
              threshold={0.5}
              tabIndex={focusable && isCarousel ? 0 : -1}
            >
              {cloneElement(child)}
            </InView>
          ))}
      </div>

      <nav className={styles.container}>
        {Children.toArray(children).map((_value, i) => (
          <div
            className={classNames(styles.indicator, {
              [styles.current]: currentIndex === i,
            })}
            key={`carousel-navigation-${i}`}
          ></div>
        ))}
      </nav>
    </>
  );
}
